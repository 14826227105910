import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import IconUI from "./ui/icon_ui";
import logoSecum from "../assets/images/logoSecum.png";
import Sello from "../assets/images/Sello.png";
import LogoAniversario from "../assets/images/mich-mejor.png";

const FooterComponet = () => {
  return (
    <>
      <div
        id="footer"
        className="shadow-sm bg-white footer p-4 margin-on-search "
      >
        <div className="container">
          <div className="d-md-flex d-none  align-items-center justify-content-center">
            <div>
              <img
                className="logoAniversario mx-5"
                src={LogoAniversario}
                alt="logo"
              />
              <img className="logoGris" src={logoSecum} alt="logo" />
              <img className="logoSello ms-5" src={Sello} alt="logo" />
            </div>
            <div>
              <div className="redes text-center d-flex justify-content-center">
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/SECULTMICH",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"22px"}>
                    <FaFacebookF />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/c/CulturaMichoac%C3%A1n",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"25px"}>
                    <AiFillYoutube />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open("https://twitter.com/SCultura_Mich", "_blank");
                  }}
                >
                  <IconUI size={"22px"}>
                    <BsTwitter />
                  </IconUI>
                </div>
              </div>
              <div className="mt-1">
                <div className="text-center text-small text-morado">
                  Enlaces de comunicación
                </div>
              </div>
            </div>
            {/* <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className='text-gray text-small my-2' >#HonestidadyTrabajo</div>
                        </div> */}
          </div>
          <div className="d-md-none d-flex  flex-column align-items-center justify-content-center">
            <div className="d-flex aling-items-center">
              <img className="logoGris" src={logoSecum} alt="logo" />
              <img className="logoSello ms-5" src={Sello} alt="logo" />
            </div>
            <div className="mt-4">
              <div className="redes text-center d-flex justify-content-center">
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/SECULTMICH",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"22px"} className="text--guinda">
                    <FaFacebookF />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/c/CulturaMichoac%C3%A1n",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"25px"}>
                    <AiFillYoutube />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn pointer"
                  onClick={() => {
                    window.open("https://twitter.com/SCultura_Mich", "_blank");
                  }}
                >
                  <IconUI size={"22px"}>
                    <BsTwitter />
                  </IconUI>
                </div>
              </div>
              <div className="mt-1">
                <div className="text-center text-small text-morado">
                  Enlaces de comunicación
                </div>
              </div>
            </div>
            {/* <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                            <div className='text-gray text-small' >#HonestidadyTrabajo</div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponet;
