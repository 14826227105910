import "./styles/estilos.css";
import "./styles/estilosMobil.css";
import ScrollToTop from "./componets/ScrollToTop";
import HomeView from "./views/HomeView";
import HeaderComponent from "./componets/HeaderComponet";
import InicioSesionView from "./views/InicioSesionView";
import NotPage from "./views/NotPage";
import TallerView from "./views/TallerView";
import ContactoView from "./views/ContactoView";
import NavBar from "./componets/NavBarComponent";
import FooterComponet from "./componets/FooterComponet";
import { BrowserRouter as Router, Navigate, useRoutes } from "react-router-dom";
import { GlobalContextProvider } from "./context/global_context";
import { NotificacionContextProvider } from "./context/notificacion_context";
import AdminView from "./views/AdminView";
import { CrearCuentaView } from "./views/CrearCuentaView";
import NotificacionUi from "./componets/ui/notificacion_ui";
import UsuariosView from "./views/Usuario";
import PagoLinea from "./componets/PagoEnLinea";
import ResetPassword from "./componets/ResetPassword";
import ProfesoresView from "./views/ProfesoresView";
// import Mantenimiento from "./componets/Mantenimiento";
import { useState } from "react";
import PagoLineaPublic from "./componets/PagoLineaPublic";
import Profile from "./componets/Profile";

const baseName =
  process.env.REACT_APP_ENV === "development" ? "/casa-cultura" : "/";

function App() {
  const [login, setLogin] = useState(localStorage.getItem("token"));

  const RouterApp = () => {
    let routes = useRoutes([
      { path: "/", element: <HomeView /> },
      { path: "/contacto", element: <ContactoView /> },
      {
        path: "/login",
        element: <InicioSesionView login={login} setLogin={setLogin} />,
      },
      { path: "/talleres", element: <TallerView /> },
      { path: "/pagoLinea", element: <PagoLineaPublic /> },
      {
        path: "/admin",
        element: login ? <AdminView /> : <Navigate to="/login" />,
      },
      { path: "/crearCuenta", element: <CrearCuentaView /> },
      {
        path: "/mis-cursos",
        element: login ? <UsuariosView /> : <Navigate to="/login" />,
      },
      {
        path: "/perfil",
        element: login ? <Profile /> : <Navigate to="/login" />,
      },
      {
        path: "/pago-en-linea/:id",
        element: login ? <PagoLinea /> : <Navigate to="/login" />,
      },
      { path: "/reset/:password", element: <ResetPassword /> },
      {
        path: "/mis-talleres",
        element: login ? <ProfesoresView /> : <Navigate to="/login" />,
      },
      { path: "/500", element: <NotPage /> },
      { path: "*", element: <Navigate to="/" /> },
    ]);
    return routes;
  };
  return (
    <div className="App">
      <GlobalContextProvider>
        <NotificacionContextProvider>
          <Router basename={baseName}>
            <ScrollToTop />
            {/* <Mantenimiento /> */}
            <HeaderComponent />
            <NotificacionUi />
            <NavBar />
            <RouterApp />
            <FooterComponet /> 
          </Router>
        </NotificacionContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default App;